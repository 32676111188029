

















import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DxLinkWrap from "../../../../Common/LinkWrap/LinkWrap.vue";

@Component({
    name: 'dx-cta',
    components: {
        DxLinkWrap
    }
})
export default class Cta extends Vue
{
    @Prop() readonly context: ContentElementContext;
    @Prop({default: true}) readonly animate: boolean;

    _contentElement: any;

    created()
    {
        // console.log('[Cta] _contentElement' , this.context.data.getAll());
        this._contentElement = this.context.data.getAll();
    };

    get contentElement()
    {
        return this._contentElement
    }

    get classes()
    {
        let classes = '';

        if (this._contentElement && this._contentElement.spacer)
        {
            classes = this._contentElement.spacer.margin + ' ' + this._contentElement.spacer.padding;
        }

        if (this._contentElement.background)
        {
            classes += ' ' + this._contentElement.background;
        }

        return classes;
    }
};
