// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../Common/Icon/Assets/drop_5.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dx-cta .dx--container[data-v-db68c3fc]:after{content:\"\";display:block;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-repeat:no-repeat;height:400px;width:400px;position:absolute;right:0;top:-50px;z-index:-1}@media (max-width:767px){.dx-cta .dx--container[data-v-db68c3fc]:after{height:200px;width:200px;top:40px;right:10px}}", "",{"version":3,"sources":["webpack://src/Component/ContentElement/Dialocx/ContentBlock/Cta/Cta.scss"],"names":[],"mappings":"AAAA,8CAA8C,UAAU,CAAC,aAAa,CAAC,wDAA+D,CAAC,uBAAuB,CAAC,2BAA2B,CAAC,YAAY,CAAC,WAAW,CAAC,iBAAiB,CAAC,OAAO,CAAC,SAAS,CAAC,UAAU,CAAC,yBAAyB,8CAA8C,YAAY,CAAC,WAAW,CAAC,QAAQ,CAAC,UAAU,CAAC","sourcesContent":[".dx-cta .dx--container[data-v-db68c3fc]:after{content:\"\";display:block;background-image:url(../../../../Common/Icon/Assets/drop_5.svg);background-size:contain;background-repeat:no-repeat;height:400px;width:400px;position:absolute;right:0;top:-50px;z-index:-1}@media (max-width:767px){.dx-cta .dx--container[data-v-db68c3fc]:after{height:200px;width:200px;top:40px;right:10px}}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
