var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      directives: [
        {
          name: "animate-onscroll",
          rawName: "v-animate-onscroll",
          value: _vm.animate
            ? "animate__animated animate__slideUpAndAppear animate__fast"
            : "",
          expression:
            "animate ? 'animate__animated animate__slideUpAndAppear animate__fast' : ''",
        },
      ],
      staticClass: "dx-cta",
      class: _vm.classes,
    },
    [
      _c("div", { staticClass: "dx--container relative text-left" }, [
        _vm.contentElement.text
          ? _c(
              "h2",
              {
                staticClass:
                  "text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-black ",
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.contentElement.text) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.contentElement.links && _vm.contentElement.links.length
          ? _c(
              "div",
              { staticClass: "flex flex-col px-4 md:px-0" },
              _vm._l(_vm.contentElement.links, function (item, index) {
                return _c(
                  "dx-link-wrap",
                  {
                    key: index,
                    staticClass: "mt-12 md:mt-9 inline-flex m:w-auto",
                    attrs: {
                      variant: "blue",
                      centered: false,
                      link: item.link,
                      rounded: true,
                      "is-button": item.isButton === "1",
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(item.label) +
                        "\n            "
                    ),
                  ]
                )
              }),
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }